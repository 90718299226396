import { render, staticRenderFns } from "./TestPrepSetCard.vue?vue&type=template&id=36a11620&scoped=true&"
import script from "./TestPrepSetCard.vue?vue&type=script&lang=js&"
export * from "./TestPrepSetCard.vue?vue&type=script&lang=js&"
import style0 from "./TestPrepSetCard.vue?vue&type=style&index=0&id=36a11620&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36a11620",
  null
  
)

export default component.exports