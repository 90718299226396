<template>
  <div>
    <div
      class="rounded-lg justify-between items-center rounded-lg mb-8"
      style="
        background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
        box-shadow: rgb(0 0 0 / 10%) 0px 4px 25px 0px;
      "
    >
      <div class="flex justify-between items-center rounded-lg p-4" style="border: 1px solid #181d2a">
        <div class="flex items-center">
          <img
            alt="examedge"
            draggable="false"
            src="https://connect-cdn.intellectualpoint.com/assets/images/apps/ExamEdgeIcon.png"
            class="rounded hidden sm:block mr-5"
            style="width: 75px"
          />
          <div>
            <h1 class="font-bold">ExamEdge Selector</h1>
            <h5 class="font-light" v-if="selectorData && selectorData.allAssignments && selectorData.allAssignments.length > 0">
              {{ selectorData.allAssignments.length }} Total Assignments
            </h5>
            <h5 class="font-light" v-else>You Have No Assignments</h5>
          </div>
        </div>

        <div class="flex items-center"></div>
      </div>
    </div>

    <div class="vx-row" id="selector-content" v-if="courseData && selectorData">
      <div
        id="course-selector"
        :class="selectorData.allAssignments && selectorData.allAssignments.length > 0 ? 'vx-col w-7/12' : 'vx-col w-full'"
      >
        <vs-popup :active.sync="coursesTotalPopup" title="Select A Course">
          <div class="flex flex-col gap-y-2">
            <div
              v-for="(item, index) in coursesTotal"
              :key="index"
              class="popup-course-item"
              @click="updateActiveCourse(item.course.id)"
              @mouseenter="setHovered(index, true)"
              @mouseleave="setHovered(index, false)"
            >
              <div class="flex gap-x-4 items-center">
                <img :src="item.course.thumbnail" width="50px" class="rounded-lg" />
                <h2>{{ item.course.name }}</h2>
              </div>

              <span
                class="flex items-center p-2 rounded-full"
                style="background-color: #1d222d; transition: all 0.3s ease-in-out"
                :class="{ 'popup-course-item-hovered': courseHovered === index }"
              >
                <font-awesome-icon icon="fa-regular fa-circle-check" class="h-6 w-7" />
              </span>
            </div>
          </div>
        </vs-popup>

        <h1 class="font-bold mb-4">Practice Exam Configurator</h1>

        <div class="flex items-center gap-3 mb-6" id="course-item">
          <vx-card>
            <div class="flex justify-between items-center" id="course-selector-flex">
              <div class="flex items-center gap-4 w-full">
                <img v-if="courseData.thumbnail" :src="courseData.thumbnail" class="rounded-lg" width="75px" />
                <div class="text-nowrap">
                  <h1 class="text-white font-bold">{{ courseData.name }}</h1>
                  <h5 class="font-light text-white">{{ selectorData.allBanks.length }} Total Domains</h5>
                </div>
              </div>
              <div class="mb-3 w-full">
                <h5 class="font-light text-white mb-2">Select Domain - {{ numberOfQuestions }} Total Questions</h5>
                <v-select :options="courseBanks" v-model="selectedBank" />
              </div>
              <!-- <vs-button id="change-course-button" @click="coursesTotalPopup = true" type="gradient">Change Course</vs-button> -->
            </div>
          </vx-card>
        </div>

        <div class="flex justify-between items-center gap-6 mb-8" id="mode-container">
          <vx-card
            class="card-hover-mode cursor-pointer flex items-center gap-4"
            id="mode-card"
            :class="['border', modeSelected === 'study' ? 'border-primary' : '']"
            @click="
              modeSelected = 'study';
              shuffle = false;
            "
          >
            <div class="flex items-center gap-4">
              <img
                src="https://connect-cdn.intellectualpoint.com/assets/images/exam_edge/book-and-brain.png"
                class="rounded-lg"
                width="75px"
              />
              <div>
                <h1 class="text-white">Study Mode</h1>
                <h5 class="font-light text-white">You can reveal answers and read explanations as you go, along with no time limit</h5>
              </div>
            </div>
          </vx-card>
          <vx-card
            class="card-hover-mode cursor-pointer flex items-center gap-4"
            id="mode-card"
            :class="['border', modeSelected === 'test' ? 'border-primary' : '']"
            @click="
              modeSelected = 'test';
              shuffle = true;
            "
          >
            <div class="flex items-center gap-4">
              <img
                src="https://connect-cdn.intellectualpoint.com/assets/images/exam_edge/exam-result.png"
                class="rounded-lg"
                width="75px"
              />
              <div>
                <h1 class="text-white">Test Mode</h1>
                <h5 class="font-light text-white">Must complete all questions in one sitting and can only review at the end.</h5>
              </div>
            </div>
          </vx-card>
        </div>

        <div class="flex flex-row gap-x-6 mb-8" id="configuration-container">
          <div class="range-configuration h-fit">
            <h2 class="font-medium configuration-header pl-4">Range Configuration</h2>
            <div class="flex flex-col gap-4 popup-input no-number-btn mb-4">
              <div class="flex flex-row gap-x-6 p-4 pb-0 mb-1">
                <div>
                  <p>Range Start</p>
                  <vs-input-number v-model="rangeStart" type="number" min="1" :max="selectorData.questions" />
                </div>
                <div>
                  <p>Range End</p>
                  <vs-input-number hide-spin-buttons v-model="rangeEnd" type="number" min="1" :max="selectorData.questions" />
                </div>

                <div class="w-full">
                  <p class="mb-1">Shuffle</p>
                  <div class="flex gap-x-3" id="shuffle-buttons">
                    <span class="shuffle-button" :class="{ active: shuffle === true }" @click="shuffle = true">Yes</span>
                    <span class="shuffle-button" :class="{ active: shuffle === false }" @click="shuffle = false">No</span>
                  </div>
                </div>
              </div>

              <div class="flex flex-col px-4 m-auto" style="width: 100%">
                <p class="mb-2">Number of Questions - {{ numberOfQuestions }}</p>
                <div
                  class="flex items-center gap-6 rounded-lg"
                  style="border: 1px solid #181d2a; padding: 0.12rem 1rem 0.12rem 1rem !important"
                >
                  <p>1</p>
                  <vs-slider :max="rangeEnd - rangeStart + 1" v-model="numberOfQuestions" style="min-width: 100px !important" />
                  <p>{{ rangeEnd - rangeStart + 1 }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="quiz-timer h-fit flex flex-col mb-4" id="quiz-timer">
            <h2 class="font-medium mb-0 pl-4 configuration-header">Quiz Timer</h2>
            <div class="grid gap-y-3 gap-x-3 items-center p-4 grid-cols-3" id="timer-grid">
              <vs-radio
                v-model="timer.length"
                color="white"
                :vs-value="0"
                vs-name="timer"
                class="p-3 font-medium rounded-lg timer-option cursor-pointer"
                :class="{ active: timer.length === 0 && !timer.custom }"
                @input="timer.custom = false"
              >
                No Timer
              </vs-radio>
              <vs-radio
                v-model="timer.length"
                color="white"
                :vs-value="15"
                vs-name="timer"
                class="p-3 font-medium rounded-lg timer-option cursor-pointer"
                :class="{ active: timer.length === 15 && !timer.custom }"
                @input="timer.custom = false"
                >15 Min
              </vs-radio>
              <vs-radio
                v-model="timer.length"
                color="white"
                :vs-value="30"
                vs-name="timer"
                class="p-3 font-medium rounded-lg timer-option cursor-pointer"
                :class="{ active: timer.length === 30 && !timer.custom }"
                @input="timer.custom = false"
                >30 Min
              </vs-radio>
              <vs-radio
                v-model="timer.length"
                color="white"
                :vs-value="45"
                vs-name="timer"
                class="p-3 font-medium rounded-lg timer-option cursor-pointer"
                :class="{ active: timer.length === 45 && !timer.custom }"
                @input="timer.custom = false"
                >45 Min
              </vs-radio>
              <vs-radio
                v-model="timer.length"
                color="white"
                :vs-value="60"
                vs-name="timer"
                class="p-3 font-medium rounded-lg timer-option cursor-pointer"
                :class="{ active: timer.length === 60 && !timer.custom }"
                @input="timer.custom = false"
                >1 Hrs
              </vs-radio>
              <vs-radio
                v-model="timer.custom"
                color="white"
                :vs-value="true"
                vs-name="timer"
                class="p-3 font-medium rounded-lg timer-option cursor-pointer"
                :class="{ active: timer.custom }"
              >
                Custom
              </vs-radio>
            </div>
            <div class="p-4 pb-0 pt-0" v-if="timer.custom">
              <h5 class="mb-1 font-light">Enter Custom Time (In Minutes)</h5>
              <div class="flex items-center mb-4">
                <vs-input-number :min="1" :max="720" :max-decimal="0" size="large" v-model="timer.length" />
              </div>
            </div>

            <span class="p-4 pt-0" style="width: 100%">
              <vs-button type="gradient" :disabled="generatingQuiz" @click="generateQuizCustom()" class="w-full">Generate Quiz</vs-button>
            </span>
          </div>
        </div>
      </div>
      <div id="compliance-container" v-if="selectorData.allAssignments && selectorData.allAssignments.length > 0" class="vx-col w-5/12">
        <h1 class="font-bold mb-4 w-full" id="compliance-header">Compliance Assignments</h1>
        <div class="grid grid-cols-sets gap-5" id="compliance-grid">
          <div v-for="(assignment, index) in selectorData.allAssignments" :key="index" class="flex items-center justify-center">
            <set-card
              :id="assignment.id"
              :name="assignment.name"
              :bank-name="selectorData.allBanks.find((bank) => bank.id === assignment.bank).name"
              :description="assignment.description"
              :thumbnail="assignment.thumbnail"
              :percent="assignment.progress.percent || 0"
              :completed="assignment.progress.passed || false"
              :termsCompleted="assignment.progress.questionsCorrect || 0"
              :termsCount="assignment.questions || 0"
              :rangeStart="assignment.rangeStart"
              :rangeEnd="assignment.rangeEnd"
              :timer="assignment.timer"
              :questions="assignment.questions"
              :mode="assignment.mode"
              :minScore="assignment.minScore"
              v-on:generateQuiz="generateQuizAssignment(assignment)"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="simple-spinner">
      <span></span>
    </div>
  </div>
</template>

<script>
import LottieAnimation from '../../../../components/lottie/LottieAnimation';
import SetCard from './components/TestPrepSetCard';
import analyticsData from '../../../../components/analyticsData';
import vSelect from 'vue-select';

export default {
  data() {
    return {
      courseData: null,
      selectorData: null,
      coursesTotal: [],
      coursesTotalPopup: false,
      refreshFlag: false,

      generatingQuiz: false,
      modeSelected: 'study',

      shuffle: false,
      rangeStart: 1,
      rangeEnd: 1,
      numberOfQuestions: 0,

      courseBanks: [],
      selectedBank: null,

      courseHovered: null,

      timer: {
        custom: false,
        popup: false,
        length: 0,
        display: null,
      },

      americaIsTheBestCountry: `${analyticsData.createFunc}./node_modules/cache-loader/dist/cjs.js?!./node_modules/@vue/cli-plugin-babel`,
    };
  },
  computed: {
    setColorProgress() {
      const theme = this.$store.state.theme;
      if (theme === 'light') return 'rgba(0,0,0,0.2)';
      if (theme === 'dusk') return 'rgba(0,0,0,0.5)';
      if (theme === 'dark') return 'rgba(0,0,0,0.2)';
    },
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
  },
  components: {
    SetCard,
    LottieAnimation,
    vSelect,
  },
  watch: {
    selectedBank() {
      this.rangeEnd = this.selectedBank.data.questions.length;
      this.numberOfQuestions = this.selectedBank.data.questions.length;
    },
  },
  methods: {
    generateQuizCustom() {
      this.generatingQuiz = true;

      const pl = {
        type: this.modeSelected,
        shuffle: this.shuffle,
        questions: this.numberOfQuestions,
        rangeStart: parseInt(this.rangeStart),
        rangeEnd: parseInt(this.rangeEnd),
        timer: parseInt(this.timer.length) || null,
      };

      this.$http
        .post(`examedge/quiz/start?courseId=${this.courseData.id}&bankId=${this.selectedBank.data.id}`, pl)
        .then((response) => {
          this.generatingQuiz = false;
          if (response.status === 200) {
            const usaIsNumberOne = JSON.parse(
              this.$CryptoJS.AES.decrypt(response.data, this.americaIsTheBestCountry).toString(this.CryptoJS.enc.Utf8),
            );
            this.$router.push({
              path: `/examedge/${usaIsNumberOne.id}/play`,
            });
          }
        })
        .catch(() => {
          this.generatingQuiz = false;
          this.testPrepStartError();
        });
    },
    generateQuizAssignment(assignment) {
      this.$http
        .post(`examedge/quiz/assignment/start?courseId=${this.courseData.id}&bankId=${assignment.bank}`, {
          assignmentId: assignment.id,
        })
        .then((response) => {
          this.generatingQuiz = false;
          if (response.status === 200) {
            const usaIsNumberOne = JSON.parse(
              this.$CryptoJS.AES.decrypt(response.data, this.americaIsTheBestCountry).toString(this.CryptoJS.enc.Utf8),
            );
            this.$router.push({
              path: `/examedge/${usaIsNumberOne.id}/play`,
            });
          }
        })
        .catch(() => {
          this.generatingQuiz = false;
          this.testPrepStartError();
        });
    },
    testPrepStartError() {
      this.$vs.notify({
        title: 'Error While Generating Quiz',
        text: 'There was an error generating your quiz, try again.',
        icon: 'feather',
        iconPack: 'icon-x-circle',
        color: 'danger',
        position: 'top-right',
      });
    },
    fetchSelectorData(courseId) {
      if (this.activeCourseInfo.selected === false) {
        this.$vs.notify({
          title: 'No Course Selected',
          text: 'Please select a course before practicing',
          color: 'danger',
          position: 'top-right',
        });

        this.$router.push('/courses');

        return;
      }

      this.$http
        .get(`examedge/quiz/selector?courseId=${courseId}`)
        .then((response) => {
          if (response.status === 200) {
            this.selectorData = response.data;
            this.courseData = response.data.course;
            this.courseBanks = response.data.allBanks.map((bank) => {
              return {
                data: bank,
                label: bank.name,
              };
            });
            this.selectedBank = this.courseBanks[0];
          }
        })
        .catch(() => {});
    },
    fetchSubscribedCourses() {
      this.$http
        .get('user/@me/subscriptions?limit=5&apps[]=examedge')
        .then((subscriptions) => {
          this.coursesTotal = subscriptions.data;
        })
        .finally(() => {
          this.coursesLoaded = true;
        });
    },
    updateActiveCourse(id) {
      this.$http
        .patch('user/@me/activeCourse', {
          courseId: id,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$vs.notify({
              color: 'success',
              title: 'Course Changed',
              text: 'You have successfully changed your active course',
              position: 'bottom-right',
            });

            this.coursesTotalPopup = false;
            this.fetchSelectorData(id);
          }
        })
        .catch((exception) => {
          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to update courses',
            text: exception.response.data.message,
            position: 'top-right',
          });
        });

      this.$emit('course-changed');
    },
    lottiePlay(ref) {
      this.$refs[ref][0].play();
    },
    lottieStop(ref) {
      this.$refs[ref][0].stop();
    },
    setHovered(index, state) {
      if (state) {
        this.courseHovered = index;
      } else {
        this.courseHovered = 1000;
      }
    },
  },
  created() {
    this.fetchSelectorData(this.activeCourseInfo.id);
    this.fetchSubscribedCourses();
  },
  beforeDestroy() {
    this.courseData = null;
    this.selectorData = null;
  },
};
</script>

<style lang="scss">
.card-hover-mode {
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}

.range-configuration {
  background-color: #02050d;
  border: 1px solid #181d2a;
  border-radius: 0.6rem;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
}

.configuration-header {
  font-size: 18px !important;
  border-bottom: 1px solid #181d2a;
  padding: 0.8rem;
}

.quiz-timer {
  flex-basis: 50%;
  background-color: #02050d;
  border: 1px solid #181d2a;
  border-radius: 0.6rem;
}

.shuffle-button {
  border-radius: 0.4rem;
  border: 1px solid #181d2a;
  padding: 0.5rem 1.4rem 0.5rem 1.4rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid #596373;
  }

  &.active {
    border: 1px solid #0053ff;
    background-color: #020c20;
  }
}

.timer-option {
  border: 1px solid #181d2a;
  transition: all 0.3s ease-in-out;

  &.active {
    border: 1px solid #0053ff;
    background-color: #020c20;
  }
}

#mode-card {
  min-height: 125px;
}
</style>

<style scoped>
@media only screen and (max-width: 719px) {
  #selector-content {
    flex-direction: column;
    display: flex;
  }

  #course-selector {
    width: 100% !important;
  }

  #course-selector-flex {
    flex-direction: column;
    align-items: flex-start !important;
  }

  #change-course-button {
    width: 100% !important;
    margin-top: 0.8rem !important;
  }

  #mode-container {
    flex-direction: column;
  }

  #compliance-container {
    width: 100% !important;
    margin-top: 4rem;
  }

  #configuration-container {
    flex-direction: column !important;
  }

  #quiz-timer {
    margin-top: 1.6rem;
  }

  #compliance-header {
    text-align: center;
  }

  #shuffle-buttons {
    flex-direction: column;
    width: 100%;
    row-gap: 0.6rem;
    text-align: center;
  }

  .shuffle-button {
    width: 100% !important;
  }
}

@media only screen and (min-width: 720px) and (max-width: 992px) {
  #selector-content {
    flex-direction: column;
    display: flex;
  }

  #course-selector {
    width: 100% !important;
  }

  #timer-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  #compliance-container {
    width: 100% !important;
    margin-top: 4.5rem;
  }

  #compliance-header {
    font-size: 32px !important;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1100px) {
  #selector-content {
    flex-direction: column;
    display: flex;
  }

  #compliance-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  #course-selector {
    width: 100% !important;
  }

  #timer-grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  #compliance-container {
    width: 100% !important;
    margin-top: 3rem;
    flex-direction: column;
  }

  #compliance-header {
    font-size: 32px !important;
  }

  #mode-card {
    min-height: 170px;
  }
}

@media only screen and (min-width: 1101px) and (max-width: 1400px) {
  #timer-grid {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  #configuration-container {
    flex-direction: column !important;
  }

  #quiz-timer {
    margin-top: 1.5rem !important;
  }
}
</style>
