<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew

========================================================================================== -->

<template>
  <vx-card>
    <div class="flex items-center justify-between" id="compliance-container">
      <div class="flex items-center">
        <img v-if="thumbnail" :src="thumbnail" width="125" class="rounded-lg hidden sm:block mr-5" draggable="false" />
        <div class="">
          <h2 class="font-bold">{{ name }}</h2>
          <div class="flex flex-row gap-x-2 mb-4" id="pill-container">
            <p
              style="width: fit-content"
              id="assignment-pill"
              :class="`flex items-center font-light rounded-full p-1 pl-4 pr-4 mt-4 ${completed ? 'bg-success' : 'bg-danger'}`"
            >
              <!-- If completed is true -->
              <feather-icon class="pr-1" v-if="completed" icon="CheckCircleIcon" svgClasses="h-4 w-4" />

              <!-- If completed is false -->
              <feather-icon class="pr-1" v-else icon="XCircleIcon" svgClasses="h-4 w-4" />

              {{ completed ? 'Completed' : 'Incomplete' }}
            </p>

            <p
              style="width: fit-content"
              id="assignment-pill"
              class="`flex items-center font-light rounded-full p-1 pl-4 pr-4 mt-4 bg-primary"
            >
              <!-- If completed is true -->
              <span>{{ questions }}</span>
              Questions
            </p>

            <p
              style="width: fit-content"
              id="assignment-pill"
              class="flex items-center font-light rounded-full p-1 pl-4 pr-4 mt-3 bg-black"
            >
              <feather-icon class="pr-1" v-if="completed" icon="ClockIcon" svgClasses="h-4 w-4" />

              {{ minScore ? `${minScore}% To Pass` : '' }}
            </p>
          </div>

          <!-- <p id="passing-score" :class="`font-light mt-2`">Bank: {{ bankName }}</p> -->
          <!-- <p id="passing-score" :class="`font-light mt-2`">{{ minScore ? `Passing Score: ${minScore}%` : '' }}</p> -->

          <vs-button color="success" icon-pack="feather" icon="icon-play" class="font-bold" id="start-button" @click="starterActive = true"
            >Start Assignment
          </vs-button>
        </div>
      </div>
      <radial-progress-bar
        :diameter="115"
        :completed-steps="termsCompleted"
        :total-steps="termsCount"
        :animateSpeed="1100"
        timingFunc="ease"
        innerStrokeColor="#181d2a"
        startColor="#31B952"
        stopColor="#0cdc62"
        :strokeWidth="7"
        :innerStrokeWidth="7"
      >
        <h2 class="font-light">{{ percent }}%</h2>
      </radial-progress-bar>
    </div>
    <vs-popup :active.sync="starterActive" style-content="max-height: 600px" :title="`Start Assignment`">
      <div class="p-3 pt-2 pb-4">
        <div class="flex items-center justify-between mb-3">
          <div class="flex items-center">
            <img :alt="name" v-if="thumbnail" :src="thumbnail" width="75" class="rounded-lg mr-4" />
            <div>
              <p>{{ bankName }}</p>
              <h1 class="font-bold">{{ name }}</h1>
              <div class="flex gap-x-2" id="desktop-header">
                <p
                  style="width: fit-content"
                  id="assignment-pill"
                  :class="`flex items-center font-light rounded-full p-1 pl-4 pr-4 mt-3 ${completed ? 'bg-success' : 'bg-danger'}`"
                >
                  <!-- If completed is true -->
                  <feather-icon class="pr-1" v-if="completed" icon="CheckCircleIcon" svgClasses="h-4 w-4" />

                  <!-- If completed is false -->
                  <feather-icon class="pr-1" v-else icon="XCircleIcon" svgClasses="h-4 w-4" />

                  {{ completed ? 'Completed' : 'Incomplete' }}
                </p>

                <p
                  style="width: fit-content"
                  id="assignment-pill"
                  class="flex items-center font-light rounded-full p-1 pl-4 pr-4 mt-3 bg-primary"
                >
                  <feather-icon class="pr-1" v-if="completed" icon="ClockIcon" svgClasses="h-4 w-4" />

                  {{ timer }} Minutes
                </p>
                <p
                  style="width: fit-content"
                  id="assignment-pill"
                  class="flex items-center font-light rounded-full p-1 pl-4 pr-4 mt-3 bg-black"
                >
                  <feather-icon class="pr-1" v-if="completed" icon="ClockIcon" svgClasses="h-4 w-4" />

                  {{ minScore ? `${minScore}% To Pass` : '' }}
                </p>
              </div>
            </div>
          </div>
          <radial-progress-bar
            :diameter="90"
            :completed-steps="percent"
            :total-steps="100"
            :animateSpeed="1100"
            timingFunc="ease"
            innerStrokeColor="#181d2a"
            startColor="#31B952"
            stopColor="#0cdc62"
            :strokeWidth="7"
            :innerStrokeWidth="7"
          >
            <h3 class="font-light">{{ percent }}%</h3>
          </radial-progress-bar>
        </div>

        <div class="mobile-header gap-x-2" style="display: none" id="mobile-header">
          <p
            style="width: fit-content"
            id="assignment-pill"
            :class="`flex items-center font-light rounded-full p-1 pl-4 pr-4 mt-3 ${completed ? 'bg-success' : 'bg-danger'}`"
          >
            <feather-icon class="pr-1" v-if="completed" icon="CheckCircleIcon" svgClasses="h-4 w-4" />

            <feather-icon class="pr-1" v-else icon="XCircleIcon" svgClasses="h-4 w-4" />

            {{ completed ? 'Completed' : 'Incomplete' }}
          </p>

          <p
            style="width: fit-content"
            id="assignment-pill"
            class="flex items-center font-light rounded-full p-1 pl-4 pr-4 mt-3 bg-primary"
          >
            <feather-icon class="pr-1" v-if="completed" icon="ClockIcon" svgClasses="h-4 w-4" />

            {{ timer }} Minutes
          </p>
        </div>

        <div class="details-grid">
          <div class="details-grid-item">
            <feather-icon class="pr-1 text-primary" icon="FileTextIcon" svgClasses="h-5 w-5" />
            <div class="flex gap-x-2">
              <p class="font-medium">Questions:</p>
              <p class="flex items-center">{{ questions }}</p>
            </div>
          </div>

          <div class="details-grid-item">
            <feather-icon class="pr-1 text-success" icon="PlayCircleIcon" svgClasses="h-5 w-5" />
            <div class="flex gap-x-2">
              <p class="font-medium">Range Start:</p>
              <p class="flex items-center">{{ rangeStart }}</p>
            </div>
          </div>

          <div class="details-grid-item">
            <feather-icon class="pr-1 text-danger" icon="XOctagonIcon" svgClasses="h-5 w-5" />
            <div class="flex gap-x-2">
              <p class="font-medium">Range End:</p>
              <p class="flex items-center">{{ rangeEnd }}</p>
            </div>
          </div>
        </div>

        <!-- <p>Range Start: {{ rangeStart }}</p>
          <p>Range End: {{ rangeEnd }}</p>
          <p>Questions: {{ questions }}</p>
          <p class="capitalize">Mode: {{ mode }}</p>
          <p class="font-light">Time Limit: {{ timer ? `${timer.toString()} Minutes` : 'No Timer' }}</p> -->

        <p class="font-semibold mb-1" v-if="description">Description</p>
        <div class="p-3 rounded-lg mb-4" v-if="description" style="background: #02050d">
          <div class="instructions-show-details" v-html="description"></div>
        </div>

        <vs-button
          class="w-full font-bold mt-6"
          icon="icon-play"
          icon-pack="feather"
          color="primary"
          @click="
            $emit('generateQuiz');
            starterActive = false;
          "
          >Start Assignment
        </vs-button>
      </div>
    </vs-popup>
  </vx-card>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress';

export default {
  data() {
    return {
      starterActive: false,
    };
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
  },
  methods: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    bankName: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: String,
      default: null,
      required: false,
    },
    percent: {
      type: Number,
      default: 0,
      required: false,
    },
    completed: {
      type: Boolean,
      default: false,
      required: false,
    },
    rangeStart: {
      type: Number,
      default: 0,
      required: false,
    },
    rangeEnd: {
      type: Number,
      default: 0,
      required: false,
    },
    questions: {
      type: Number,
      default: 0,
      required: false,
    },
    timer: {
      type: Number,
      default: 0,
      required: false,
    },
    mode: {
      type: String,
      required: false,
    },
    termsCompleted: {
      type: Number,
      default: 0,
      required: false,
    },
    termsCount: {
      type: Number,
      default: 0,
      required: false,
    },
    minScore: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  components: {
    RadialProgressBar,
  },
};
</script>

<style scoped>
.mobile-header {
  display: flex;
}

@media only screen and (max-width: 719px) {
  #assignment-pill {
    padding: 0.2rem 0.6rem 0.2rem 0.6rem !important;
  }

  #start-button {
    width: 100% !important;
    margin-top: 1.2rem !important;
  }

  #passing-score {
    display: none;
  }

  .details-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  #desktop-header {
    display: none !important;
  }

  #mobile-header {
    display: flex !important;
  }
}

@media only screen and (max-width: 429px) {
  #pill-container {
    flex-direction: column !important;
    width: 100% !important;
  }

  #assignment-pill {
    width: 100% !important;
    text-align: center;
    justify-content: center;
  }

  #compliance-container {
    column-gap: 0.4rem !important;
  }
}
</style>
